import { github, linkedin, mail, mailbox, phone } from "assets";
import { SectionTitle } from "components/section_title";
import { FunctionComponent } from "react";
import { ContactForm } from "./contact_form";

export const ContactUs: FunctionComponent = () => {
    return (
        <section className="flex items-center justify-center flex-col mt-10">
            <SectionTitle title="Contact Me" />
            <div className={`flex justify-center items-center flex-col my-6 md:my-1 sm:flex-row`}>
                <img src={mailbox} alt="" className={`w-[90%] sm:w-[45%] md:w-[40%] xl:w-[30rem] 2xl:w-[35rem] 3xl:w-[60rem] 4k:w-[80rem] sm:mr-4 md:mr-8 lg:mr-10`}/>
                <ContactForm />
            </div>
            <div className="flex flex-col sm:flex-row mb-8">
                <div className="flex mb-8 sm:mb-0 items-center justify-center">
                    <a href="https://github.com/AimadBahdir" rel="noreferrer" target="_blank" className="flex items-center justify-center text-rid-400 text-[10pt] font-semibold sm:text-xs md:text-lg md:mt-1 2xl:text-xl 4k:text-3xl 4k:mt-4 ">
                        <img src={github} alt="" className="w-14 h-14 mx-2 inline" />
                    </a>
                    <a href="https://www.linkedin.com/in/aimadbahdir" rel="noreferrer" target="_blank" className="flex items-center justify-center text-rid-400 text-[10pt] font-semibold sm:text-xs md:text-lg md:mt-1 2xl:text-xl 4k:text-3xl 4k:mt-4 ">
                        <img src={linkedin} alt="" className="w-14 h-14 mx-2 inline" />
                    </a>
                </div>
                <div className="flex flex-col items-start">
                    <a href="mailto:contact@ridhab.com" rel="noreferrer" target="_blank" className="flex items-center justify-center text-rid-400 text-[10pt] font-semibold sm:text-xs md:text-lg md:mt-1 2xl:text-xl 4k:text-3xl 4k:mt-4 ">
                        <img src={mail} alt="" className="w-5 h-5 mx-2 inline" />
                        contact@ridhab.com
                    </a>
                    <a href="tel:+212700230022" rel="noreferrer" target="_blank" className="mt-2 flex items-center justify-center text-rid-400 text-[10pt] font-semibold sm:text-xs md:text-lg md:mt-1 2xl:text-xl 4k:text-3xl 4k:mt-4 ">
                        <img src={phone} alt="" className="w-5 h-5 mx-2 inline" />
                        +212 7 00 23 00 22
                    </a>
                </div>
            </div>
        </section>
    );
}