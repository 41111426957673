import { storyboard } from "assets";
import { FunctionComponent } from "react";
import { SectionTitle } from "./section_title";

export const MyStory: FunctionComponent = () => {

    function expYears() {
        let diff = (Date.now() - new Date(2016,7,11).getTime()) / 1000;
        diff /= (60 * 60 * 24 * 7 * 52);
        return Math.abs(Math.round(diff));
    }
    return (
        <section className="flex justify-center items-center flex-col md:flex-row">
            <img src={storyboard} alt="My Story" className="w-[90%] md:w-[42%] xl:w-[35rem] 2xl:w-[45rem] 3xl:w-[55rem] 4k:w-[80rem]"/>
            <div className="w-[90%] md:w-[50%]">
                <SectionTitle title="My Story"/>
                <p className="text-rid-400 font-normal text-justify text-[10pt] sm:text-[12pt] md:text-[10pt] lg:text-[12pt] xl:text-[14pt] 2xl:text-[18pt] 3xl:text-[25pt] 4k:text-[35pt]">
                    Hi, my name is Aimad and I am a front-end developer with experience in both web and mobile. I began my journey in the field of design using Photoshop as a hobby {expYears()} years ago. As I interacted more with websites, I became interested in programming and attended a institute to gain the basics.
                    I then graduated from the institute and joined school 1337, which is part of the 42 network group and known for its unique self-learning and peer-learning approach.<br/>
                    Through this, I have gained mastery in various programming languages and technologies, combining my passion for design and programming to bring my creations to life.
                </p>
            </div>
        </section>
    );
}