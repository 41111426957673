import { circles } from "assets";
import { FunctionComponent } from "react";

export const SkillSection : FunctionComponent<{rtl?: boolean, title: string, image: string, description: string}> = ({rtl = true, title, image, description}) => {
    return (
        <div className={`flex justify-center items-center flex-col my-8 md:my-1 ${rtl ? "sm:flex-row" : "sm:flex-row-reverse"}`}>
            <img src={image} alt={title} className={`w-[90%] sm:w-[45%] md:w-[40%] xl:w-[30rem] 2xl:w-[35rem] 3xl:w-[60rem] 4k:w-[80rem] ${rtl ? "sm:mr-6 md:mr-12 lg:mr-20" : "sm:ml-6 md:ml-12 lg:ml-20"}`}/>
            <div className="w-[90%] sm:w-[45%] :w-[40%] lg:w-[33%]">
                <div className="relative my-8">
                    <img src={circles} alt="" className="absolute z-0 -left-3 xs:-left-5 sm:-left-6 2xl:-left-10 bottom-2 md:bottom-0 lg:bottom-2 2xl:bottom-0 4k:bottom-6 w-[2rem] xs:w-[3rem] lg:w-[4rem] 2xl:w-[6rem]"/>
                    <h2 className="relative text-spa z-5 text-rid-500 font-extrabold text-3xl xs:text-4xl md:text-3xl lg:text-5xl 2xl:text-6xl 3xl:text-7xl 4k:text-8xl" >{title}</h2>
                </div>
                <p className="text-rid-500 font-normal text-justify text-[10pt] sm:text-[9pt] xl:text-[10pt] 2xl:text-[12pt] 3xl:text-[18pt] 4k:text-[22pt]">
                    {description}
                </p>
            </div>
        </div>
    );
}