import { arrow, avatar, bg, techs } from "assets";
import { ContactUs } from "components/contact_section/contactus";
import { FooterSection } from "components/footer_section/footer";
import { MyStory } from "components/mystory";
import { MySkills } from "components/skills_section/myskills";
import { FunctionComponent } from "react";

const Header : FunctionComponent = () => {
    return (
        <section className="relative min-h-[600px] md:min-h-[720px] 2xl:min-h-[1080px] 3xl:min-h-[2000px] 4k:min-h-[2400px] h-screen flex flex-col items-center text-center justify-center bg-cover bg-center bg-opacity-70" style={{backgroundImage: `url(${bg})`}}>
            <div className="relative flex items-center justify-center mt-16">
                <img src={avatar} alt="Aimad BAHDIR" className="rounded-full z-10 border-2 border-rid-700 w-32 md:w-40 md:border-3 2xl:w-52 2xl:border-4 4k:w-96 4k:border-6"/>
                <img src={techs} alt="" className="absolute z-0 min-w-[20rem] md:min-w-[25rem] 2xl:min-w-[32rem] 4k:min-w-[60rem] rays"/>
            </div>
            <h1 className="text-rid-300 text-xl font-extrabold mt-4 sm:text-2xl md:text-3xl md:mt-6 2xl:text-4xl 4k:text-6xl 4k:mt-14 ">
                Aimad Bahdir
            </h1>
            <h2 className="text-rid-500 text-[8pt] font-normal sm:text-xs md:text-lg md:mt-1 2xl:text-xl 4k:text-3xl 4k:mt-4 ">
                Mobile & Web Frontend Developer
            </h2>
            <p className="relative text-rid-400 max-w-[15rem] text-2xl font-extrabold my-6 2xs:text-3xl 2xs:max-w-[20rem] xs:max-w-[28rem] xs:text-5xl xs:mt-8 sm:max-w-[30rem] md:max-w-lg md:text-6xl md:mt-10 2xl:max-w-2xl 2xl:text-7xl 4k:max-w-6xl 4k:text-9xl 4k:mt-24 mb-40">
                Where Design & Code Converge
                <img src={arrow} alt="" className="w-[14rem] xs:w-[18rem] sm:w-[20rem] md:w-[24rem] xl:w-[26rem] 2xl:w-[30rem] 4k:w-[50rem] absolute -right-6 xs:-right-4 md:-right-16 4k:-right-24 top-4 xs:top-6 md:top-2 xl:-top-2 4k:top-8 rotate-2 xs:rotate-12 md:mt-8"/>
            </p>
        </section>
    );
}

export const HomePage: FunctionComponent = () => {
    return (
        <>
            <Header/>
            <MyStory/>
            <MySkills/>
            <ContactUs/>
            <FooterSection/>
        </>
    );
}
