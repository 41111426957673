import { design, mobiledev, webdev } from "assets";
import { FunctionComponent } from "react";
import { SectionTitle } from "../section_title";
import { SkillSection } from "./skill_section";

export const MySkills : FunctionComponent = () => {
    return (
        <section className="flex items-center justify-center flex-col mt-10">
            <SectionTitle title="My Skills"/>
            <SkillSection image={webdev} title="Web Dev" description={`
                As a web developer, I possess a strong skill set in building dynamic and responsive web applications using ReactJS and NextJS frameworks, with a solid understanding of TypeScript.
                My experience in styling includes proficiency in CSS and Sass, and I am well-versed in using popular frameworks such as Tailwind and Bootstrap to enhance the visual design of my projects.
                I am able to create high-quality, user-friendly websites that meet the needs of clients and end-users.
                I am committed to staying up to date with the latest web development technologies and trends to deliver the best results for my clients.
            `}/>
            <SkillSection rtl={false} image={mobiledev} title="Mobile Dev" description={`
                As a mobile developer, I have expertise in designing and developing mobile applications for both iOS and Android platforms using Flutter and Dart.
                I chose Flutter because it's a modern, open-source framework that allows for the creation of high-performance, visually appealing and responsive mobile apps.
                With my skills in Flutter and Dart, I am able to create rich user interfaces, access native features and APIs, and implement complex logic and business rules.
                Additionally, I have a solid understanding of the basics of app development, including navigation, state management, and data persistence. My choice of using Flutter is driven by its growing popularity and the ability to create engaging and dynamic apps with a single codebase that can run on multiple platforms.
            `}/>
            <SkillSection image={design} title="UI/UX Design" description={`
                I am a skilled UI/UX designer with experience in both Photoshop and Figma.
                I have a strong understanding of user interface design principles and user experience design methodologies, allowing me to create intuitive and user-friendly interfaces.
                I use Photoshop to create visually stunning designs and mockups, and Figma to collaborate and prototype with team members.
                Additionally, I have experience with user research, usability testing, and user-centered design processes.
                I chose to use both Photoshop and Figma because they are both industry-standard tools that offer a wide range of features and capabilities, making them the perfect choice for creating professional and high-quality designs.
                With the combination of these two tools, I am able to create visually stunning designs while also keeping in mind the user experience and design principles.
            `}/>
        </section>
    );
}