import { x_shape } from "assets";
import { FunctionComponent } from "react";

export const SectionTitle: FunctionComponent<{title: string}> = ({ title }) => {
    return (
        <div className="relative my-8">
            <img src={x_shape} alt="" className="absolute z-0 -left-3 xs:-left-5 sm:-left-6 2xl:-left-10 bottom-2 md:bottom-0 lg:bottom-2 2xl:bottom-0 4k:bottom-6 w-[2rem] xs:w-[3rem] sm:w-[4rem] md:w-[3rem] lg:w-[4rem] 2xl:w-[6rem]"/>
            <h2 className="relative text-spa z-5 text-rid-500 font-extrabold text-3xl xs:text-4xl sm:text-5xl md:text-3xl lg:text-5xl 2xl:text-6xl 3xl:text-7xl 4k:text-8xl" >{title}</h2>
        </div>
    );
};