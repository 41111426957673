import { notfound } from "assets";
import { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";

export const NotFound: FunctionComponent = () => {
    return (
        <main className="flex justify-center items-center h-screen flex-col " >
            <img src={notfound} alt="" className="w-[80%] sm:w-[60%] md:w-[50%] lg:w-[40%] xl:w-[35rem] 2xl:w-[50rem] 4k:w-[80rem]"/>
            <NavLink to="/" className="relative bottom-5 mx-auto mb-4 text-rid-300 text-2xl font-bold hover:text-rid-500">Go Back</NavLink>
        </main>
    );
}