import './styles/index.css';
import { createRoot } from 'react-dom/client';
import { HomePage } from './pages/home';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { FunctionComponent } from 'react';
import { NotFound } from 'pages/notfound';

const AppRoot : FunctionComponent = () => {
    return (
      <main className='select-none bg-gradient-to-b from-rid-900 via-rid-800 to-rid-900 min-h-screen min-w-[280px] font-pop relative overflow-hidden'>
        <RouterProvider router={
          createBrowserRouter([
            { path: "/", element: <HomePage/> },
            { path: "*", element: <NotFound/> },
          ])} />
      </main>
    );
}

createRoot(document.getElementById("root") as HTMLElement).render(<AppRoot/>);
