import React, { FunctionComponent, useRef } from 'react';

export const ContactForm: FunctionComponent = () => {
    const nameRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const messageRef = useRef<HTMLTextAreaElement>(null);
    const infoRef = useRef<HTMLParagraphElement>(null);
    const submitRef = useRef<HTMLButtonElement>(null);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        infoRef.current!.innerText = '';
        if (nameRef.current!.value.trim() === '') {
            nameRef.current!.classList.add('border-[1.5px]', 'border-err-500/60', 'bg-err-500/40', 'placeholder:text-err-500/30');
            nameRef.current!.classList.remove('border-none', 'bg-rid-800', 'placeholder:text-rid-700');
            infoRef.current!.classList.add('text-err-500');
            infoRef.current!.innerText = 'Please enter your name';
            return;
        } else {
            nameRef.current!.classList.remove('border-[1.5px]', 'border-err-500/60', 'bg-err-500/40', 'placeholder:text-err-500/30');
            nameRef.current!.classList.add('border-none', 'bg-rid-800', 'placeholder:text-rid-700');
            infoRef.current!.classList.remove('text-err-500');
        }

        if (emailRef.current!.value === '' || /\S+@\S+\.\S+/.test(emailRef.current!.value) === false) {
            emailRef.current!.classList.add('border-[1.5px]', 'border-err-500/60', 'bg-err-500/40', 'placeholder:text-err-500/30');
            emailRef.current!.classList.remove('border-none', 'bg-rid-800', 'placeholder:text-rid-700');
            infoRef.current!.classList.add('text-err-500');
            infoRef.current!.innerText = 'Please enter a valid email';
            return;
        } else {
            emailRef.current!.classList.remove('border-[1.5px]', 'border-err-500/60', 'bg-err-500/40', 'placeholder:text-err-500/30');
            emailRef.current!.classList.add('border-none', 'bg-rid-800', 'placeholder:text-rid-700');
            infoRef.current!.classList.remove('text-err-500');
        }
        
        if (messageRef.current!.value.trim() === '') {
            messageRef.current!.classList.add('border-[1.5px]', 'border-err-500/60', 'bg-err-500/40', 'placeholder:text-err-500/30');
            messageRef.current!.classList.remove('border-none', 'bg-rid-800', 'placeholder:text-rid-700');
            infoRef.current!.classList.add('text-err-500');
            infoRef.current!.innerText = 'Please enter your message';
            return;
        }
        else {
            messageRef.current!.classList.remove('border-[1.5px]', 'border-err-500/60', 'bg-err-500/40', 'placeholder:text-err-500/30');
            messageRef.current!.classList.add('border-none', 'bg-rid-800', 'placeholder:text-rid-700');
            infoRef.current!.classList.remove('text-err-500');
        }

        const name = nameRef.current?.value;
        const email = emailRef.current?.value;
        const message = messageRef.current?.value;
        if (name && email && message && process.env.REACT_APP_CONTACT_FORM_API) {
            const data = {
                name,
                email,
                subject: "Portfolio Contact Form",
                message
            };
            submitRef.current!.innerText = 'SENDING ...';
            submitRef.current!.disabled = true;
            const resp = await fetch(process.env.REACT_APP_CONTACT_FORM_API, {
                method: 'POST',
                body: JSON.stringify(data)
            });
            if (resp.status === 200) {
                nameRef.current!.value = '';
                emailRef.current!.value = '';
                messageRef.current!.value = '';
                infoRef.current!.innerText = 'Message sent successfully!\nI will get back to you asap via email 😉.';
            }
            submitRef.current!.innerText = 'SEND MESSAGE';
            submitRef.current!.disabled = false;
            
        }
    }

    return (
        <form className="w-[90%] sm:w-[45%] :w-[40%] lg:w-[33%] flex flex-col items-center" onSubmit={handleSubmit}>
            <input ref={nameRef} type="text" name="name" id="name" placeholder='FULL NAME' className="bg-rid-800 text-rid-400 outline-none px-3 py-2 font-medium bg-transparent border-none rounded-lg text-md placeholder:text-rid-700 my-2 w-[80%] sm:w-[100%]"/>
            <input ref={emailRef} type="text" name="email" id="email" placeholder='YOUR EMAIL' className="bg-rid-800 text-rid-400 outline-none px-3 py-2 font-medium bg-transparent border-none rounded-lg text-md placeholder:text-rid-700 my-2 w-[80%] sm:w-[100%]"/>
            <textarea ref={messageRef} name="message" id="message" placeholder='YOUR MESSAGE HERE ...' className="bg-rid-800 text-rid-400 outline-none px-3 py-2 font-medium bg-transparent border-none rounded-lg text-md placeholder:text-rid-700 my-2 min-h-[10rem] w-[80%] sm:w-[100%]"/>
            <button ref={submitRef} type="submit" className="flex bg-rid-800 text-rid-400 outline-none px-4 py-2 font-semibold bg-transparent border-none rounded-xl text-sm placeholder:text-rid-700 my-2">
                SEND MESSAGE
            </button>
            <p ref={infoRef} className='text-rid-400 text-sm font-medium text-center max-w-[250px]'></p>
        </form>
    );
}