import { heart, resume } from "assets";
import resumefile from "assets/docs/Resume.pdf";
import { FunctionComponent } from "react";

export const FooterSection: FunctionComponent = () => {
    return (
        <footer className="flex flex-col justify-center items-center my-6 3xl:my-12">
                <p className="text-rid-500 text-[8pt] font-normal sm:text-xs md:text-lg md:mt-1 2xl:text-xl 4k:text-3xl 4k:mt-4 ">
                    Designed & Built with 
                    <img src={heart} alt="" className="w-5 h-5 mx-1 inline" />
                     by <b>ME</b> 
                </p>
                <a href={resumefile} download="AimadBahdir-Resume" target="_blank" rel="noreferrer" >
                    <img src={resume} alt="" title="Resume" className="fixed bottom-6 right-6 w-12 h-12 cursor-pointer mx-1 inline" />
                </a>
        </footer>
    );
}